.faq {
  padding-top: ac(150px, 60px);

  h2 {
    margin-bottom: ac(60px, 34px);

    .round {
      /* position: relative;
      top: -0.1em; */

      border-color: var(--primary);
      padding-top: ac(3px, 4px);
      padding-bottom: ac(7px, 8px);

      /* .word {
        position: relative;
        top: 0.1em;
      } */
    }
  }

  @mixin media 551 {
    h2 {
      gap: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}
