h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 130%;
}

h1 {
  font-size: ac(100px, 40px);
  line-height: normal;
  font-weight: 700;

  @mixin media 374 {
    font-size: 36px;
  }

  @mixin media 338 {
    font-size: 32px;
  }
}

h2 {
  font-family: var(--font-jeko);
  font-weight: 800;

  font-size: ac(56px, 34px);
  line-height: 125%;
}

h3 {
  font-size: ac(46px, 38px);
  line-height: normal;
  font-weight: 600;
}

p,
li {
  font-size: ac(22px, 16px);
  font-weight: 300;
  line-height: 144%;
}

a {
  font-size: ac(20px, 16px);
  display: inline-block;
  line-height: 100%;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-jeko);
    font-weight: 800;
    margin-bottom: 20px;
  }

  h2 {
    font-size: ac(34px, 28px);
  }

  h3 {
    font-size: ac(30px, 24px);
    margin-bottom: 22px;
  }

  ol,
  ul {
    li {
      position: relative;

      list-style-position: outside;

      padding-left: 1.5ch;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.7em;
        transform: translateY(-50%);

        width: 0.35em;
        height: 0.35em;

        border-radius: 999px;

        background: currentColor;
      }
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  p {
    font-size: ac(22px, 16px);
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }
}
