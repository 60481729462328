.video-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    transition: transform 0.5s ease;
    z-index: 1000;
    background: var(--black);

    video,
    iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @mixin media 651 {
            object-fit: contain;
        }
    }

    .close-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        cursor: pointer;
        transition: transform 0.2s ease;
        z-index: 100;

        display: flex;
        justify-content: center;
        align-items: center;

        top: 2%;
        right: 2%;
        background: var(--primary);
        border-radius: 50%;

        svg {
            width: 42%;
            height: 42%;
        }

        &:hover {
            transform: rotate(45deg);
        }
    }

    &.active {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;

        video,
        iframe {
            opacity: 1;
        }
    }
}
