:root {
  --black: #222222;
  --primary: #ff5500;
  --white: #ffffff;
  --grey: #d1d1d1;
  --error: #ff033e;

  --font-main: 'Helvetica Neue LT Pro', sans-serif;
  --font-secondary: 'futura-pt', sans-serif;
  --font-gibson: 'canada-type-gibson', sans-serif;
  --font-jeko: 'Jeko', sans-serif;

  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
}

@keyframes slide-t {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
