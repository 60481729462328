@font-face {
  font-family: 'icomoon';
  src: url('../../assets/icons/icomoon.eot?vnui8z');
  src: url('../../assets/icons/icomoon.eot?vnui8z#iefix')
      format('embedded-opentype'),
    url('../../assets/icons/icomoon.woff2?vnui8z') format('woff2'),
    url('../../assets/icons/icomoon.ttf?vnui8z') format('truetype'),
    url('../../assets/icons/icomoon.woff?vnui8z') format('woff'),
    url('../../assets/icons/icomoon.svg?vnui8z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: '\e900';
}

.icon-arrow-bg {
  display: block;

  width: 16px;
  height: 16px;

  background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 1L14 6.5M14 6.5L8.5 12M14 6.5L1.5 6.5' stroke='%23FF5500' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}
