.termsfeed-com---palette-dark.termsfeed-com---nb {
  background-color: var(--black) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 10px;

  bottom: 10px;
  right: 10px;
}

.cc-nb-main-container {
  padding: 2rem !important;
}

.termsfeed-com---palette-dark .cc-nb-title,
.termsfeed-com---palette-dark .cc-nb-text {
  font-family: var(--font-jeko);
}

.termsfeed-com---palette-dark .cc-nb-okagree,
.termsfeed-com---palette-dark .cc-nb-reject,
.termsfeed-com---palette-dark .cc-cp-foot-save {
  background-color: var(--primary) !important;
  color: var(--black) !important;
  border-radius: 999px;

  transition: all ease 250ms;

  &:hover {
    background-color: var(--grey) !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--primary) !important;
  }
}

.termsfeed-com---palette-dark .cc-nb-changep {
  border-radius: 999px;
  background-color: var(--grey) !important;
  color: var(--black) !important;

  transition: all ease 250ms;

  &:hover {
    background-color: var(--primary) !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--primary) !important;
  }
}

/* popup */
.termsfeed-com---palette-dark .cc-pc-container {
  background: var(--black) !important;

  font-family: var(--font-jeko);
}

.termsfeed-com---palette-dark .cc-pc-head,
.termsfeed-com---palette-dark .cc-cp-foot,
.termsfeed-com---palette-dark .cc-cp-body-content {
  background: var(--black) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs {
  background: var(--grey) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link {
  color: var(--black) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active='true'] button {
  color: var(--primary) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-close:active,
.termsfeed-com---palette-dark .cc-pc-head-close:focus {
  border: 2px solid var(--primary) !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-tabs-item {
  background: transparent !important;
}

.cc-pc-head-lang-select {
  background: var(--black) !important;
  color: var(--white) !important;
}
