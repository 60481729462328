.how-section {
  padding-top: 60px;
  padding-bottom: ac(150px, 30px);
  overflow: hidden;

  .cont {
    max-width: 1054px;
  }

  h2 {
    margin-bottom: 90px;

    .round {
      border-color: var(--primary);
      padding-top: ac(3px, 4px);
      padding-bottom: ac(3px, 4px);
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 100px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      @mixin media 769 {
        flex-direction: column !important;
        gap: 40px;
      }

      @mixin media 451 {
        gap: 50px;

        flex-direction: column-reverse !important;
      }
    }

    &__num-wrap {
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 40px;
    }

    &__mob-illustration {
      display: none;
      max-width: 125px;

      @mixin media 551 {
        display: block;
      }
    }

    &__illustration {
      @mixin media 769 {
        max-width: 200px;
      }

      @mixin media 551 {
        display: none;
      }
    }

    &__content {
      max-width: 500px;
    }

    &__num {
      font-size: ac(100px, 60px);
      font-weight: 700;
      padding: 8px 11px;
      background: var(--primary);
      border-radius: 39px;
      color: var(--black);
      min-width: 81px;
      text-align: center;
    }

    &__title {
      font-family: var(--font-jeko);
      font-weight: 800;

      font-weight: 800;
      margin-top: 40px;
      margin-bottom: 30px;
      font-size: ac(46px, 24px);
    }

    .content-element {
      p {
        font-size: ac(22px, 18px);
      }
    }

    @mixin media 451 {
      gap: 60px;
    }
  }

  @mixin media 451 {
    h2 {
      margin-bottom: 30px;
    }
  }
}
