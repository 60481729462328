.load-screen {
  position: fixed;
  inset: 0;
  z-index: 999999;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: var(--black);
  visibility: visible;

  /* transition: all ease 200ms; */

  &.hidden {
    display: none;
  }

  &.loaded {
    background: transparent;
    visibility: hidden;
    pointer-events: none;
  }

  &__logo {
    width: 450px;

    @mixin media 769 {
      width: 80%;
      max-width: 300px;
    }
  }

  &__rect-list {
    position: absolute;
    inset: 0;

    display: grid;
    grid-template-columns: repeat(20, minmax(0, 1fr));

    width: 100%;
    height: 100%;

    @mixin media 769 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
  }

  &__rect {
    visibility: hidden;
    opacity: 0;

    background: var(--primary);

    transition: all ease 250ms;

    &.showed {
      visibility: visible;
      opacity: 1;
    }
  }
}

.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) transparent;

  scroll-behavior: smooth;
}

[data-scroll-pined],
[data-scroll] {
  will-change: transform;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;

  color: var(--white);
  font-family: var(--font-jeko);
  font-weight: 300;

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  background: var(--black);

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary);
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--black);
  background: var(--primary);
}

::selection {
  color: var(--black);
  background: var(--primary);
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.noise-bg {
  background: url('../../assets/images/noise-bg.jpg') center / cover
    var(--black);
  background-attachment: fixed;
  background-blend-mode: overlay;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1276px;
  width: perc(1276);

  @mixin max-lg {
    width: 89.33%;
  }

  @mixin min-media 1450 {
    max-width: 1640px;
    /* width: perc(1640); */
  }
}

section {
  position: relative;
}

.logo {
  display: flex;
  align-items: center;

  /* width: fit-content; */
  /* height: 100%; */
  min-height: 50px;
  /* width: ac(178px, 118px);
  @mixin aspect-ratio 162, 43; */
  flex-shrink: 0;
  transition: 0.3s ease;

  img:not(img.top-logo) {
    width: 100%;
    height: 100%;
    min-height: 50px;
    max-height: 70px;

    object-fit: contain;
    object-position: center;
  }

  &:hover {
    transform: scale(1.06);
  }
}

.round-fill {
  padding: 4px 16px;
  background: var(--primary);
  border-radius: 40px;
  color: var(--black);
  display: inline-block;
}

.round {
  border-radius: 68px;
  border: 1px solid var(--white);
  padding: 4px 16px;
  display: inline-block;

  &[data-view] {
    border-color: transparent;
  }

  &.animated {
    border-color: var(--white);
  }
}

.subtitle {
  font-size: ac(28px, 16px);
  font-weight: 700;
}

.bg-title {
  max-width: 1242px;
  margin: 0 auto;
  pointer-events: none;
  user-select: none;

  h1 {
    font-size: ac(200px, 155px, 1024);
    text-align: center;
    line-height: 85%;

    @mixin media 1025 {
      font-size: ac(155px, 60px, 375, 1024);
    }

    @mixin media 551 {
      transform: translateX(-5%);
    }
  }
}

.video-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.3;
  z-index: -1;
  filter: brightness(0.75);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.splitting-text-animation {
  overflow: hidden;

  max-width: 1100px;

  margin: 0 auto ac(40px, 20px);
  padding-bottom: 10px;

  font-family: var(--font-jeko);
  /* vertical-align: bottom; */
  /* font-family: var(--font-secondary); */
  font-size: ac(80px, 30px);
  font-weight: 800;
  line-height: 1.37;
  /* text-transform: uppercase; */

  opacity: 0;
  transition: opacity 500ms ease-in-out;

  .primary {
    padding: 0;

    color: var(--primary);
  }

  .round {
    display: inline-flex;
    align-items: center;

    line-height: 1;
    padding: 4px ac(40px, 30px);
    border-radius: 0;
    border: none;
    position: relative;

    @mixin media 601 {
      padding: 4px 15px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      width: calc(100% - 30px);
      height: 100%;

      border-radius: 68px;
      border: 1px solid var(--white);
      display: inline-block;
      transition: 750ms ease-in-out;

      @mixin media 601 {
        width: calc(100% - 10px);
      }
      /* transition-delay: 0.4s; */
    }

    /*.word {
            .inner {
                --line-index: 0;
            }
        }*/
  }

  .word {
    overflow: hidden;
    display: inline-flex;
    /* padding-bottom: 5px; */

    .char {
      padding-bottom: 5px;

      transition-timing-function: ease;
      transition-duration: 400ms;
      transition-delay: calc(150ms * (var(--word-index) + 1));
      transform: translateY(105%);
      display: inline-block;
    }
  }

  @mixin media 1180 {
    max-width: 80%;
  }

  @mixin media 551 {
    max-width: unset;
  }

  &.show {
    opacity: 1;

    .round {
      &:before {
        opacity: 1;
        transform: translateX(-50%) scale(1);
      }
    }

    .word {
      .char {
        transform: translateY(0);
      }
    }
  }
}

.slide-animation-words {
  --time: 1000ms;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  gap: 0.15em;

  /* display: flex;
  align-items: center; */

  opacity: 0;

  transition: all cubic-bezier(0.75, 0.4, 0.37, 0.74) 400ms;

  @mixin media 551 {
    opacity: 1;
  }

  .word {
    /* margin-bottom: -10px; */

    overflow: hidden;
  }

  /* .round {
    .word {
      margin-bottom: 0;
    }
  } */

  /* .whitespace {
    display: inline-block;
    width: 1rem;
    line-height: 1;
    margin-bottom: -10px;
  } */

  .char {
    --delay: calc(var(--time) / var(--word-total) * var(--word-index));
    opacity: 0;
    transform: translateY(100%);

    transition: all 500ms cubic-bezier(0.75, 0.4, 0.37, 0.74) var(--delay);

    @mixin media 551 {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .primary-text {
    color: var(--primary);
  }

  &.animate-by-char {
    .char {
      --delay: calc(var(--time) / var(--char-total) * var(--char-index));
    }
  }

  &.animated {
    opacity: 1;

    .char {
      opacity: 1;
      transform: translateY(0);
    }
  }

  & > * {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.15em;
  }

  &--center {
    justify-content: center;

    & > * {
      justify-content: center;
    }
  }
}

.slide-animation-line {
  opacity: 0;

  transition: all ease-in-out 250ms;

  .word {
    --delay: calc(1000ms / var(--line-total) * var(--line-index));
    opacity: 0;
    transform: translateY(100%);

    transition: all 600ms ease-out var(--delay);
  }

  &.animated {
    opacity: 1;

    .word {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

#smooth-content {
  will-change: transform;
}

[data-simplebar] {
  padding-right: 20px;
  margin-right: -20px;

  .simplebar-scrollbar:before {
    background: var(--black);
    width: 6px;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
}

.hero-screen {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 120px;

  @mixin media 601 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__offer {
    h1 {
      margin-bottom: ac(20px, 10px);

      .round {
        .word .inner {
          padding: 7px ac(30px, 18px);
        }
      }

      .words {
        /* display: inline-block;*/

        /*&.animated {
                    animation: slide-t 1s linear forwards;
                }*/

        &[data-splitting] {
          .round {
            max-height: ac(142px, 59px);
          }

          .word {
            &[data-word='Growth'] {
              color: var(--primary);
            }
          }

          .round {
            margin-right: ac(20px, 10px);
            overflow: hidden;
          }
        }
      }
    }

    .content-element {
      max-width: 900px;
      margin: 0 auto ac(60px, 70px);

      p {
        font-size: ac(22px, 16px);
      }
    }

    .btn:hover + .info-block__text {
      opacity: 1;
      visibility: visible;
      transform: rotateY(0) translateY(0);
    }

    .info-block {
      position: relative;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        .info-block__text {
          background: var(--primary);
          right: calc(100% - 50px);

          @mixin media 901 {
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            right: unset;
          }
        }
      }

      &:nth-child(2) {
        .info-block__text {
          top: calc(100% + 10px);
          left: calc(50%);
          transform: translateX(-50%);
          background: var(--primary);
        }
      }

      &:nth-child(3) {
        .info-block__text {
          left: calc(100% - 50px);
          background: var(--primary);

          @mixin media 901 {
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            right: unset;
          }
        }
      }
    }

    .info-block__text {
      padding: 25px;
      color: var(--black);
      border-radius: 39px;
      transition: 0.3s ease-in-out;
      min-width: 235px;

      transform: rotateY(80deg) translateY(40px);

      opacity: 0;
      visibility: hidden;

      position: absolute;
      top: calc(100% - 25px);

      p {
        font-size: 16px;
      }

      @mixin media 901 {
        /* display: none; */
      }
    }

    @mixin media 551 {
      .info-block {
        width: 100%;
      }

      .btn {
        max-width: 225px;
        width: 100%;
      }
    }
  }

  @mixin max-lg {
    .cont {
      width: 86%;
    }
  }

  &__counts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 606px;

    gap: 30px;

    @mixin media 1024 {
      grid-template-columns: repeat(3, 1fr);
    }

    @mixin media 769 {
      grid-template-columns: 1fr;
      width: 100%;
      max-width: 100%;
    }

    .count-item {
      opacity: 0;

      transition: opacity ease 250ms;
    }

    &.animated {
      .count-item {
        opacity: 1;
      }
    }
  }

  &--evolve {
    .hero-screen__offer {
      flex: 1;

      h1 {
        font-family: var(--font-jeko);
        font-weight: 800;

        margin-bottom: ac(58px, 24px);
        white-space: nowrap;

        &::after {
          content: '';
          position: relative;
          top: 2px;
          left: -0.25em;

          display: inline-block;

          width: 0.15em;
          height: 0.15em;

          border-radius: 999px;
          margin-left: 5px;

          background: var(--primary);
        }
      }

      .content-element {
        margin: 0;
        max-width: 525px;
      }
    }

    .cont {
      max-width: 1270px;
    }

    .count-item {
      border-radius: 39px;
      background: var(--primary);
      color: var(--black);
      padding: 25px 30px;

      max-width: 288px;

      p {
        margin-bottom: 47px;
        font-weight: 700;
      }

      &__num {
        font-size: ac(56px, 44px);
        font-weight: 700;

        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      @mixin media 769 {
        max-width: 100%;

        &__num {
          justify-content: flex-start;
        }
      }
    }
  }
}

.hero-screen--evolve {
  @media screen and (min-width: 651px) and (max-width: 1025px) {
    min-height: auto;
    padding-bottom: 10px;
    padding-top: 200px;
  }
}

.about-section {
  padding: ac(150px, 60px) 0;

  h2 {
    /* color: var(--white);
    font-size: ac(34px, 24px);
    font-weight: 500;*/
    margin-bottom: ac(50px, 40px);
  }

  .content-element {
    h2 {
      font-size: ac(36px, 26px);
      font-weight: 500;
    }

    h3 {
      font-family: var(--font-main);
      font-weight: 500;
    }
  }
}

.hub-hero {
  padding: ac(287px, 140px) 0 150px;

  h1 {
    margin-bottom: 50px;
    font-family: var(--font-jeko);
    font-weight: 800;

    margin-left: -0.08ch;

    &::after {
      content: '';
      position: relative;
      top: 2px;

      display: inline-block;

      width: 15px;
      height: 15px;

      border-radius: 999px;
      margin-left: 5px;

      background: var(--primary);
    }
  }

  &__text {
    color: var(--grey);
    max-width: 800px;
    margin-bottom: 60px;
  }

  .checkboxes {
    max-width: 1145px;
  }

  .hub-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @mixin media 901 {
      grid-template-columns: 1fr;
    }
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  h1 {
    font-size: ac(64px, 32px);

    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &::after {
      content: '';
      position: relative;
      top: 2px;

      display: inline-block;

      width: 13px;
      height: 13px;

      border-radius: 999px;
      margin-left: 5px;

      background: var(--primary);
    }
  }
  h2 {
    font-size: ac(48px, 24px);

    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  h3 {
    font-size: ac(37px, 18px);
  }
  h4 {
    font-size: ac(32px, 16px);
  }
  h5 {
    font-size: ac(26px, 13px);
  }
  h6 {
    font-size: ac(21px, 11px);
  }

  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    /* list-style: circle; */

    li {
      position: relative;

      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        top: 0.75em;
        transform: translateY(-50%);
        left: 0;
        width: 10px;
        height: 10px;

        border-radius: 999px;

        background: var(--primary);
      }
    }
  }

  ol {
    padding-left: 20px;
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;
    color: var(--primary);

    &:hover {
      opacity: 0.7;
    }
  }
}

.page404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    &-accent {
      color: var(--primary);
    }
  }

  &__title,
  p {
    margin-bottom: 15px;
    text-align: center;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.accent-title {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  font-size: ac(56px, 34px);

  .round-fill {
    position: relative;
    overflow: hidden;

    display: inline-flex;
    align-items: center;

    background: var(--grey);
  }

  &[data-view] {
    opacity: 0;
    transform: translateX(-25%);
    transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);

    .round-fill {
      --fz: ac(56px, 34px);
      --padding-y: ac(3px, 4px);
      --padding-x: 30px;

      transition: all 1s cubic-bezier(0.16, 1, 0.3, 1) 0.25s;
      /* transition: all 1s cubic-bezier(0.16, 1, 0.3, 1) 1s; */

      font-size: var(--fz);

      transform: scale(0.5);
      opacity: 0;
      background: var(--primary);

      /* position: relative;
      top: -0.1em; */

      [data-text] {
        /* position: relative;
        top: 0.1em; */
        transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
        /* transition: all 1s cubic-bezier(0.16, 1, 0.3, 1) 1s; */

        /* &::before {
          content: attr(data-text);
          display: block;
          position: absolute;
          text-wrap: nowrap;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: var(--fz);
          color: var(--black);
          font-weight: 700;
          transform: translateY(150%) skewY(15deg);
          transition: all 1s cubic-bezier(0.16, 1, 0.3, 1) 1s;
        } */
      }

      /* &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        transform: translateY(100%);
        background: var(--primary);
        border-radius: 50px;
        transition: all 1s cubic-bezier(0.4, 0, 0, 1) 1s;
      } */

      /* opacity: 0; */

      /* transition: all 1s cubic-bezier(0.16, 1, 0.3, 1) 1s; */
    }

    &.animated {
      transform: translateX(0);
      opacity: 1;

      .round-fill {
        transform: scaleX(1.02);
        opacity: 1;

        /* transition: all 0.6s cubic-bezier(0.34, 5.56, 0.64, 1) 1s; */
        /* background: transparent; */

        /* &::before {
          transform: translateY(0);
          border-radius: 0;
          transition-duration: 0.5s;
        } */

        /* [data-text] {
          transform: translateY(calc(-100% - var(--padding-y) / 2));

          &::before {
            transform: translateY(100%) skewY(0);
          }
        } */
      }
    }
  }

  &&--invert {
    .round-fill {
      /* background: var(--black); */
      /* color: var(--primary); */
      background: var(--grey);
      color: var(--black);

      /* &::before {
        background: var(--grey);
      } */
    }
  }

  &&--primary {
    .round-fill {
      background: var(--primary);
    }
  }
}

/* FONT Fix for non ios devices */
/* body:not(&.ios) {
  .splitting-text-animation {
    .round {
      padding-top: 0.25em;
      padding-bottom: 0;
    }
  }

  .accent-title {
    &[data-view] {
      .round-fill {
        position: relative;
        top: -0.1em;
      }

      [data-text] {
        position: relative;
        top: 0.1em;
      }
    }
  }

  .brands {
    h2:not(.brands-info h2) {
      position: relative;
      top: -0.1em;

      .word {
        position: relative;
        top: 0.1em;
      }
    }
  }

  .faq {
    h2 {
      .round {
        position: relative;
        top: -0.1em;

        .word {
          position: relative;
          top: 0.1em;
        }
      }
    }
  }
} */

.firefox {
  textarea ~ .focus-line {
    bottom: 1px;
  }
}

form {
  position: relative;

  & > * {
    transition: all ease 500ms;
  }
}

form.form-sent {
  & > *:not(.form-thanks) {
    visibility: hidden;
    opacity: 0;
  }

  .form-thanks {
    opacity: 1;
    visibility: visible;
    /* transform: translate(-50%, -50%); */

    .char {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.form-thanks {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);

  grid-column: 1/-1;

  width: 100%;

  opacity: 0;
  visibility: hidden;

  text-align: center;
}

.wrap {
  width: 100%;
}
