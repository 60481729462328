.values {
    background: var(--primary);
    color: var(--black);
    padding: ac(172px, 60px) 0 ac(185px, 60px);

    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ac(166px, 30px, 1440, 1920);
    }

    &__info {
        max-width: 391px;

        h2 {
            --time: 500ms;
            display: flex;
            align-items: center;
            gap: 13px;

            margin-bottom: 25px;

            .round-fill {
                display: inline-flex;

                padding-top: ac(3px, 4px);
                padding-bottom: ac(3px, 4px);

                background: var(--grey);
            }
        }
    }

    .marquee-vertical {
        max-height: 355px;
        flex-shrink: 0;
    }

    .marquee__content {
        align-items: flex-start;

        h2 {
            line-height: 1;
        }
    }

    @mixin media 769 {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }

        .marquee-vertical,
        &__info {
            max-width: 100%;
        }
    }
}
