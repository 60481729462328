[data-adaptive-cursor] {
    position: relative;

    &:hover {
        .adaptive-cursor {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.2);
        }
    }

    &:active {
        .adaptive-cursor {
            transform: translate(-50%, -50%) scale(0.5) !important;
        }
    }
}

.adaptive-cursor {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: -1;

    display: block;

    width: 24px;
    height: 24px;

    border-radius: 999px;

    background: var(--primary);
    opacity: 0;
    pointer-events: none;

    transition: setTransition(200ms, opacity, left, top, transform);

    &.adaptive-cursor--invert {
        z-index: 1;
        background: transparent;
        backdrop-filter: invert(1);
    }
}
