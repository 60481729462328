.latest-works {
  padding: ac(287px, 140px) 0 200px;

  h1 {
    margin-bottom: ac(60px, 30px);

    font-family: var(--font-jeko);
    font-weight: 800;

    &::after {
      content: '';
      position: relative;
      top: 2px;

      display: inline-block;

      width: 0.2em;
      height: 0.2em;

      border-radius: 999px;
      margin-left: 5px;

      background: var(--primary);
    }
  }

  .work-list {
    margin-top: 30px;
  }
}

.work-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-rows: 1fr;

  @mixin media 901 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}
