/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(31px, 10px) 0;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  opacity: 1;

  &.show-on-scroll {
    opacity: 0;

    @mixin media 1025 {
      opacity: 1;
    }
  }

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.scrolled:not(.active) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px) !important;
    padding: ac(16px, 10px) 0;

    .logo {
      /* transform: scale(0.8); */
      img:not(img.top-logo) {
        object-position: left center;
      }
    }
  }

  &.top {
    .logo {
      img:not(img.top-logo) {
        opacity: 0;
        width: 0;
        transition-delay: 0ms;
      }

      .top-logo {
        opacity: 1;
        width: ac(162px, 115px);
        transition-delay: 250ms;
      }
    }
  }

  .logo {
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;

    .top-logo {
      opacity: 0;
      width: 0;
    }

    img {
      transition: all ease 250ms;
    }

    img:not(img.top-logo) {
      width: 40px;
      transition-delay: 250ms;
    }

    &:hover {
      transform: scale(1.06);
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .menu,
  &__navbar {
    display: flex;
    align-items: center;
    gap: ac(50px, 20px, 1024);
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .menu__item {
    position: relative;

    &.dropdown {
      & > .menu__link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  .menu__link {
    position: relative;
    font-size: 16px;

    line-height: normal;

    transition: all 0.3s ease;
    cursor: pointer;

    opacity: 0.5;

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--cl-grey);
      transition: all 0.3s ease;
    }

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;

      &::before {
        width: 100%;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }

  .header__navigation {
    transition: 0.3s ease;
  }

  &.active {
    transition: 0.3s ease;

    .header__navigation {
      opacity: 0;
      visibility: hidden;
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */
.mobile-header {
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.4s ease;
  /* transform: translateY(100%); */
  opacity: 0;
  visibility: hidden;
  z-index: 998;
  background: var(--black);
  /* background: #000000; */

  width: 100%;
  height: 100%;

  .video-bg {
    z-index: 0;
    /* opacity: 1; */
    /* left: 50%; */
  }

  .simplebar-scrollbar:before {
    background: var(--primary);
    opacity: 1;
  }

  &__projects,
  &__navbar {
    width: 100%;
  }

  &__navbar {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 20px;

    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    /* height: calc(var(--vh, 1vh) * 100 - 174px); */

    transition: transform 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* transform: translateY(60%); */

    .simplebar-content-wrapper {
      display: flex;

      .simplebar-content {
        margin: auto;
      }
    }
  }

  .cont {
    height: calc(var(--vh, 1vh) * 100);
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: ac(25px, 19px);
    width: fit-content;
    margin-left: auto;

    @mixin min-media 1536 {
      margin: 0 auto;
    }
  }

  .menu__link {
    font-weight: 700;
    font-size: ac(56px, 34px);
    overflow: hidden;
    position: relative;
    padding-bottom: 5px;

    opacity: 0.5;

    transition: ease-in-out 0.3s;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: var(--white);
      transition: 0.6s ease;
    }

    span {
      display: inline-block;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);

      &:after {
        content: attr(data-text);
        display: block;
        position: absolute;
      }

      &:after {
        top: 105%;
        transform: skewY(20deg);
        transform-origin: left top;
        transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &:hover {
      opacity: 1;

      &:after {
        width: 100%;
      }

      span {
        transform: translateY(-110%);

        &:after {
          transform: skewY(0deg);
        }
      }
    }

    &.active {
      opacity: 1;

      &:after {
        width: 100%;
      }
    }
  }

  &__projects {
    padding-bottom: 90px;

    display: flex;
    align-items: flex-end;
  }

  .project {
    &__img {
      z-index: -1;
      width: 50%;

      transition: transform 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      /* transform: translateX(-50%); */

      &,
      &:before {
        position: absolute;

        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
      }

      &:before {
        content: '';
        width: 100%;
        background: var(--black);
        opacity: 0.4;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__desc {
      transition: transform 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      /* transform: translateY(100px); */

      h3,
      h2 {
        position: relative;
        z-index: 1;
        font-weight: 500;
      }

      h3 {
        font-size: ac(34px, 30px);
        margin-bottom: 10px;
      }

      h2 {
        font-size: ac(46px, 42px);
        margin-bottom: 20px;
      }
    }
  }

  .book-btn {
    display: none;
  }

  .btn--white {
    --padding-x: 30px;
    --padding-y: 12px;
  }

  &.active {
    opacity: 1;
    visibility: visible;

    /* .project__img {
      transform: translateX(0);
    }

    .project__desc {
      transform: translateY(0);
    }

    .mobile-header__navbar {
      transform: translateY(0);
    } */
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  z-index: 100;
  cursor: pointer;
  transition: transform 0.3s ease;

  overflow: hidden;

  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.4s;
    user-select: none;

    width: 80px;
    height: 80px;
    flex-shrink: 0;

    path {
      fill: none;
      stroke: var(--white);
      stroke-width: 2;
      transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;
      stroke-dasharray: 40 172;
    }
  }

  &.active {
    transform: rotate(45deg);

    svg path {
      &.top {
        stroke-dashoffset: -68px;
      }

      &.bottom {
        stroke-dashoffset: -68px;
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */

@mixin mobile-menu-start-point {
  .header {
    &__navigation,
    .book-btn {
      display: none;
    }

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px) !important;
  }

  .mobile-header {
    &__projects {
      display: none;
    }

    &__navbar {
      padding: 0 20px 0 0;
      width: 100%;
      margin-top: 0;
    }

    .cont {
      flex-direction: column;
      padding: 150px 0 62px;
      justify-content: space-between;
    }

    .menu {
      margin-left: 0;
    }

    .book-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      text-align: center;
      padding: 19px 60px 20px;
      min-height: 60px;
      line-height: normal;
    }
  }
}
