body.safari {
  .why-evolve {
    &:not(:first-child) {
      padding-top: 60px;

      @mixin media 641 {
        margin-top: 0;
      }
    }
  }
}

.why-evolve {
  padding: 60px 0 50px;
  /* overflow: hidden; */

  &:not(:first-child) {
    padding-top: 0;

    @mixin media 641 {
      margin-top: -24px;
    }
  }

  .accordion__item {
    padding: 10px 30px;
  }

  .accordion__header {
    opacity: 1;

    font-size: ac(38px, 22px);
    line-height: 1.2;
  }

  .accordion__body {
    .content-element {
      max-height: 400px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  h2 {
    margin-bottom: ac(50px, 30px);

    @mixin max-sm {
      margin-left: -5.335%;
      margin-right: -5.335%;
      padding: 0 5.335%;
      overflow-x: hidden;
    }

    @mixin media 551 {
      gap: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    /* .round-fill {
            padding-top: ac(3px, 4px);
            padding-bottom: ac(8px, 6px);
        } */
  }

  &__content {
    width: fit-content;
    flex: 1;

    .content-element {
      max-width: 600px;

      * {
        font-family: var(--font-main);
        font-weight: 400;
      }

      h1,
      h2 {
        font-family: var(--font-jeko);
        font-weight: 600;
      }

      h3,
      h4 {
        font-family: var(--font-jeko);
        font-size: ac(30px, 20px);
        font-weight: 600;
      }

      p {
        font-size: ac(18px, 14px);
      }

      ol {
        li {
          font-size: ac(18px, 14px);
        }
      }

      ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 10px ac(20px, 10px);

        li {
          position: relative;

          font-size: ac(16px, 14px);
          font-weight: 300;
          margin-bottom: 0;
          padding-left: 20px;
          /* line-height: 1.2; */

          list-style: none;

          &::before {
            content: '';
            position: absolute;
            transform: translateY(-50%);
            top: 0.7em;
            left: 4px;

            width: 6px;
            height: 6px;
            border-radius: 999px;
            background: var(--white);
          }
        }
      }
    }
  }

  + .pin-spacer {
    .cont {
      padding-top: ac(42px, 0px);
    }
  }

  .accordion {
    max-width: 606px;
    flex: 1;
  }

  @mixin media 1025 {
    &__wrap {
      flex-direction: column;
    }

    &__content,
    .accordion,
    .content-element {
      width: 100%;
      max-width: 100%;
    }
  }

  @mixin min-media 1450 {
    .content-element {
      max-width: 660px;
    }

    .accordion {
      max-width: 800px;
    }
  }
}

.evolve-stats {
  padding: ac(110px, 50px) 0;

  background: var(--grey);
  color: var(--black);
}

.evolve-stats-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 30px;

  @mixin media 1025 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @mixin media 415 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.evolve-stats-item__num {
  margin-bottom: 10px;

  font-size: ac(109px, 56px);
  font-weight: 700;
  line-height: 0.98;

  .suffix {
    font-size: ac(56px, 24px);
  }
}

.evolve-stats-item__progress {
  display: flex;
  align-items: center;

  max-width: 162px;
  height: ac(50px, 30px);

  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left center;
  }
}

.evolve-stats-item__title {
  font-size: ac(30px, 20px);
  font-weight: 500;
  line-height: 1.36667;
}
