.accordion {
  display: flex;
  flex-direction: column;
  gap: ac(40px, 28px);

  &__item {
    border-radius: 40px;
    border: 1px solid var(--primary);
    transition: background 0.3s ease, padding 0.3s ease;
    padding: 7px 30px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      .accordion__header {
        opacity: 1;
      }
    }

    &.active {
      &:not(.accordion--faq .accordion__item) {
        /* padding: 7px 30px 30px; */
      }

      .accordion__header {
        margin-bottom: 20px;
        opacity: 1;
      }

      .accordion__body {
        opacity: 1;
      }
    }

    &.active,
    &:hover {
      &:not(.accordion--faq .accordion__item) {
        color: var(--black);
        background: var(--primary);
      }
    }
  }

  &__header {
    opacity: 0.5;

    font-size: ac(46px, 26px);
    font-family: var(--font-jeko);
    font-weight: 600;

    width: 100%;
    transition: 0.3s ease;

    &-subtitle {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.2;
    }

    @mixin media 360 {
      text-align: center;
      font-size: 22px;
    }
  }

  &__body {
    height: 0;
    transition: 0.3s ease;
    opacity: 0;
    width: 100%;

    .content-element {
      max-height: 154px;

      p {
        font-size: 22px;
      }

      ul {
        padding-left: 2px;
      }

      @mixin media 551 {
        p {
          font-size: 16px;
        }
      }
    }
  }

  &--faq {
    .accordion__item {
      border: none;
      border-bottom: 1px solid var(--grey);
      padding: 0 0 ac(40px, 24px);
      border-radius: 0;

      &.active {
        .accordion__header {
          margin-bottom: ac(50px, 20px);
        }

        .plus {
          &:before {
            background-image: url('data:image/svg+xml,<svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="17" height="3" fill="%23222222"/></svg>');
          }
        }
      }
    }

    .accordion__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      font-size: ac(28px, 20px);

      .plus {
        width: ac(30px, 24px);
        height: ac(30px, 24px);
        background: var(--primary);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &:before {
          content: '';
          width: ac(17px, 12px);
          height: ac(17px, 12px);
          background: url('data:image/svg+xml,<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.301 6.69901H17V10.301H10.301V17H6.69901V10.301H0V6.69901H6.69901V0H10.301V6.69901Z" fill="%23222222"/></svg>')
            no-repeat center / contain;
        }
      }
    }
  }
}

.accordion__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid var(--primary);
  padding: 12px 30px;
  border-radius: 999px;

  background: var(--primary);
  color: var(--black);

  font-size: ac(34px, 20px);
  font-weight: 600;
  line-height: 1;

  transition: background ease 500ms;

  &:hover {
    background: var(--black);
    color: var(--primary);

    .accordion__btn-arrow {
      background: var(--primary);
      color: var(--black);
    }
  }

  &-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 33px;
    height: 33px;
    border-radius: 999px;

    background: var(--black);
    color: var(--primary);
    font-size: 12px;

    transition: all ease 500ms;
  }
}
