.footer {
  /* border-top: 1px solid var(--white); */
  padding: ac(90px, 60px) 0 ac(90px, 60px);
  color: var(--white);
  /* background: var(--primary); */

  .logo {
    width: fit-content;
    height: ac(42px, 35px);

    margin-bottom: ac(28px, 18px);

    min-height: unset !important;

    img {
      min-height: unset !important;
    }
  }

  &__privacy-link {
    width: fit-content;

    margin-bottom: 10px;

    font-size: 18px;
    font-weight: lighter !important;
    line-height: 1.5;
  }

  &__credits {
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: auto;

    &-text {
      font-size: 14px;

      color: var(--grey);
    }
  }

  a {
    font-weight: 400;

    transition: setTransition(all);

    &:hover {
      color: var(--primary);
    }
  }

  h2 {
    font-size: ac(109px, 64px);
    max-width: 240px;
    line-height: 1;
  }

  h3 {
    font-size: ac(30px, 22px);
    font-weight: 500;
    margin-bottom: 14px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    /* margin-bottom: ac(88px, 30px); */
    /* margin-right: 60px; */

    @mixin media 1025 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @mixin media 551 {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__col {
    max-width: 288px;

    @mixin media 551 {
      max-width: unset;

      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--white);
      }
    }

    &-title {
      position: relative;

      margin-bottom: ac(14px, 5px);

      font-size: 30px;
      font-weight: 500;
      letter-spacing: -0.6px;

      &::after {
        content: '';
        position: relative;
        top: 2px;

        display: inline-block;

        width: 10px;
        height: 10px;

        margin-left: 5px;
        border-radius: 999px;

        background: var(--primary);
      }
    }

    &-items {
      display: flex;
      flex-direction: column;
      gap: ac(20px, 10px, 1024);
    }

    &-item {
      &-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;

        color: var(--grey);

        a {
          font-size: 18px;
          font-weight: lighter;
          line-height: 1.5;

          color: var(--white);
        }

        @mixin media 551 {
          max-width: 300px;
        }
      }

      &-value {
        font-size: 16px;
        line-height: 1.56;

        a {
          font-weight: lighter;
        }
      }
    }
  }

  .adaptive-cursor {
    background: var(--primary);
    z-index: 1;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    gap: 28px;

    &__link {
      font-size: ac(22px, 18px);
      font-weight: 400;
      line-height: 144%;
      position: relative;
      z-index: 2;

      .font-bold {
        font-size: ac(22px, 20px);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      li {
        width: fit-content;
      }

      &--contact {
        li {
          width: 100%;
        }
      }
    }

    &__nav {
      width: 100%;
      max-width: 185px;

      &:last-child {
        max-width: 335px;
      }
    }
  }

  @mixin media 1025 {
    &__wrap {
      flex-direction: column;
      gap: 20px;
      margin-right: 0;
    }

    .menu {
      justify-content: space-between;
    }
  }

  @mixin media 769 {
    .menu {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .menu__nav {
        &:last-child {
          grid-column: -1 / 1;

          .menu__link {
            width: fit-content;
            display: inline-flex;
            gap: 16px;
          }
        }
      }
    }
  }

  @mixin media 551 {
    h2 {
      font-size: 40px;
    }
  }
}

.footer-form {
  .slide-animation-words .word {
    padding-bottom: ac(14px, 10px);

    @media (max-width: 640px) {
      overflow: visible;
    }
  }
}

