.footer-form {
  padding: ac(150px, 100px) 0;
  position: relative;
  z-index: 10;

  &__heading {
    margin-bottom: ac(90px, 50px);

    h2 {
      line-height: 1;
    }
  }

  h2 {
    font-size: ac(106px, 40px);
    text-align: center;
    margin-bottom: ac(50px, 20px);
    /* line-height: normal; */
  }

  .round-fill {
    text-align: center;
    font-size: ac(34px, 16px);
    font-weight: 500;

    @mixin media 360 {
      font-size: 14px;
    }
  }

  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: ac(50px, 40px);
    max-width: 1030px;
    margin: 0 auto;

    .btn {
      width: fit-content;
      margin: 10px auto 0;
      --fz: ac(36px, 20px);
      --padding-x: 30px;
      --padding-y: 12px;
    }

    @mixin media 551 {
      grid-template-columns: 1fr;
      grid-row-gap: ac(50px, 30px);
    }
  }
}
