.checkbox {
  position: relative;
  color: var(--white);

  --fz: ac(16px, 12px, 1440, 1920);
  --padding-y: 8px;
  --padding-x: ac(14px, 8px, 1440, 1920);

  border-radius: 39px;

  overflow: hidden;
  transition: all 0.3s ease;

  [data-text] {
    display: inline-block;
    padding: var(--padding-y) var(--padding-x);
    font-size: var(--fz);

    position: relative;
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);

    &:before {
      content: attr(data-text);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;

      position: absolute;
      width: 100%;
      height: 100%;
      top: 100%;
      left: 0;
      font-size: var(--fz);
      color: var(--white);
      transform: translateY(100%) skewY(15deg);
      transition: all 0.5s ease;
      text-align: center;
    }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 10;

    &:checked {
      & + {
        [data-text] {
          transform: translateY(calc(-100%));

          &:before {
            transform: skewY(0);
            background: var(--primary);
          }
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid var(--primary);
    border-radius: 39px;
  }

  &:hover {
    transform: scaleX(1.02);
    transition: transform 0.6s cubic-bezier(0.34, 5.56, 0.64, 1);
  }
}
