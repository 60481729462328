.contact-hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 150px 0;

  .contact-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 45px;
  }

  .contact-content {
    /* max-width: vw(500); */

    h1 {
      max-width: 700px;

      font-family: var(--font-jeko);
      font-size: ac(80px, 42px, 1024, 1920);
      font-weight: 800;
      line-height: 110%;

      &:not(:last-chid) {
        margin-bottom: 34px;
      }

      .primary {
        color: var(--primary);
      }

      @mixin media 1025 {
        text-align: center;
      }

      /* &::after {
        content: '';
        position: relative;
        top: 2px;

        display: inline-block;

        width: 15px;
        height: 15px;

        border-radius: 999px;
        margin-left: 5px;

        background: var(--primary);
      } */
    }
  }

  .contact-form {
    max-width: 50%;

    font-size: ac(80px, 42px, 1024, 1920);
    padding-top: 4rem;

    .input-item {
      font-size: 16px;
    }

    textarea {
      resize: none;
      min-height: 120px;

      ~ .focus-line {
        bottom: 5px;
      }
    }

    &__nav {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      .btn {
        --fz: ac(34px, 26px);
        --padding-x: 25px;
        --padding-y: 8px;

        &.hide {
          opacity: 0;
          pointer-events: none;
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }

  @mixin media 1025 {
    .contact-wrap {
      flex-direction: column;
      gap: 40px;
    }

    .contact-content,
    .contact-form {
      max-width: 100%;
    }
  }
}
