.project-section {
  margin-bottom: ac(100px, 60px);

  &:last-child {
    margin: ac(150px, 100px) 0;
  }
}

.project-hero {
  padding-top: ac(287px, 140px);
  padding-bottom: ac(80px, 0px);
  margin-bottom: 0;
  /* height: 100vh; */
  min-height: ac(620px, 550px, 1024);

  opacity: 0;

  transition: all ease-in-out 500ms;

  &.animated {
    opacity: 1;
  }

  h1 {
    margin-bottom: ac(92px, 40px);

    .word {
      &:last-child {
        position: relative;

        padding-right: 0.75ch;

        &::after {
          content: '.';
          /* position: absolute;
          bottom: 0.5rem;
          right: 0; */

          display: inline-block;

          /* width: 0.75rem;
          height: 0.75rem; */

          margin-left: 5px;
          border-radius: 999px;

          color: var(--primary);
          /* background: var(--primary); */
          opacity: 0;
          /* scale: 0; */
          transition: all 250ms calc((var(--word-index) + 2) * 250ms) ease;

          font-size: ac(100px, 40px);
        }
      }
    }
    &.animated {
      .word {
        &:last-child {
          &::after {
            opacity: 1;
            scale: 1;
          }
        }
      }
    }
  }

  .testimonial-preview {
    max-width: 705px;

    &__avatar {
      width: 71px;
      height: 71px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      font-size: ac(22px, 18px);
      font-weight: 700;
    }

    p {
      font-size: ac(18px, 16px);
    }

    .content-element {
      margin-top: 27px;

      p {
        font-size: 16px;

        &::before {
          content: '“';
        }

        &::after {
          content: '”';
        }
      }
    }
  }

  .tags {
    &__item {
      border-radius: 39px;
      background: var(--primary);
      color: var(--white);
      font-size: ac(22px, 18px);
      padding: 8px 26px;
    }
  }

  .cont {
    max-width: 1270px !important;
  }

  @mixin media 1025 {
    min-height: unset;
    height: auto;

    margin-bottom: ac(100px, 60px);
  }
}

.hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  height: 100vh;
  width: 100vw;
  z-index: 1000;
  transition: all ease 750ms;
  /* transform: scale(0.65);
  opacity: 0; */

  .hero-image__wrap {
    width: 100%;
    height: 100%;
    transform: scale(0.65);
    transition: all ease 750ms;
    opacity: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    margin: 0 auto;
    border: 5px solid #fff;
    border-bottom-color: var(--primary);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    transition: all ease 250ms;
  }

  &:has(img.is-show) {
    &::before {
      opacity: 0;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  img {
    position: absolute;
    inset: 0;
    opacity: 0;

    height: 100vh;
    width: 100vw;
    object-fit: cover;
    margin: 0 auto;
    transform: scale(1.2);
    transition: transform 750ms ease-in-out, opacity 750ms ease-in-out;

    &.is-show {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.second-animation {
    transform: translateY(0);

    &::before {
      opacity: 0;
      transition: none;
    }

    img {
      opacity: 0;
      transition: none;
    }
  }

  &.animated {
    z-index: 2;

    .hero-image__wrap {
      transform: translateY(0);
      opacity: 1;
    }

    &::before {
      z-index: -1;
    }

    &.second-animation {
      &::before {
        transition: all 500ms ease-in-out 500ms;
        opacity: 1;
      }

      img.is-show {
        transition: all 500ms ease-in-out;

        opacity: 1;
        transform: scale(1);
      }

      img:not(img.is-show) {
        display: none;
      }
    }
  }

  @mixin media 1025 {
    position: relative;
    z-index: 10;
    height: 400px;
    overflow: hidden;

    opacity: 0;

    /* opacity: 1; */

    img.is-show {
      position: static;
      width: 100%;
      height: 100%;

      /* object-fit: contain; */
    }

    img {
      object-fit: cover;

      width: 100%;
      height: 100%;
    }

    &.animated {
      opacity: 1;
    }

    &.second-animation {
      transform: translateY(0);
      opacity: 1;

      &::before {
        opacity: 1;
      }

      img.is-show {
        opacity: 1;
        transition: transform 500ms ease-in-out;

        transform: scale(1);
      }
    }
  }
}

.about-project {
  .subtitle {
    margin-bottom: 30px;
  }

  &__content {
    max-width: 712px;

    .subtitle {
      /* &::after {
        content: '';
        position: relative;
        top: 2px;
        left: -5px;

        display: inline-block;

        width: 0.25em;
        height: 0.25em;

        border-radius: 999px;
        margin-left: 5px;

        background: var(--primary);
      } */
    }

    @mixin min-media 1920 {
      max-width: 1000px;
    }
  }

  &__details {
    max-width: 394px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ac(40px, 30px);
    grid-column-gap: 30px;

    &.without-client {
      grid-template-columns: repeat(3, minmax(0, auto));
      max-width: unset;

      /* .detail {
        &.full-line {
          grid-column: unset;
        }
      } */
    }

    .detail {
      h3 {
        font-size: ac(22px, 18px);
        margin-bottom: 10px;

        /* &::after {
          content: '';
          position: relative;
          top: 2px;

          display: inline-block;

          width: 0.25em;
          height: 0.25em;

          border-radius: 999px;
          margin-left: 5px;

          background: var(--primary);
        } */
      }

      p {
        color: var(--grey);
        font-size: ac(18px, 16px);
      }

      a {
        color: var(--grey);
        font-size: ac(18px, 16px);

        transition: color ease 250ms;

        &:hover {
          color: var(--primary);
        }
      }

      &.full-line {
        grid-column: 1 / -1;
      }
    }
  }

  .content-element {
    p {
      /* font-size: ac(30px, 24px); */
      font-size: ac(18px, 16px);
      font-weight: 400;
    }
  }

  & + .project-image {
    margin-bottom: ac(100px, 60px);
  }
}

.project-image {
  margin-bottom: 20px;

  &.long-image {
    img {
      width: 83%;
    }
  }

  img {
    margin: 0 auto 20px;
    width: 100%;
  }
}

.project-details {
  .details-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    justify-content: space-between;

    gap: ac(40px, 10px);

    @mixin min-media 1540 {
      /* justify-content: space-between; */
      grid-template-columns: repeat(auto-fit, minmax(225px, max-content));
    }
  }

  .details-block {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:first-child {
      max-width: 248px;
    }

    &:nth-child(2) {
      /* max-width: 184px; */
      /* margin: 0 vw(134) 0 vw(70); */
    }

    .subtitle {
      font-size: ac(16px, 14px);
      margin-bottom: ac(50px, 30px);
      min-height: 1.3em;

      @mixin min-media 1560 {
        font-size: 18px;
      }
    }

    &__font {
      margin-bottom: 30px;

      img {
        object-fit: contain;
        max-height: 160px;
      }
    }

    h3 {
      font-size: ac(30px, 24px);
      font-weight: 400;
    }

    &--palette {
      width: fit-content;
      /* margin-right: 40px; */

      .subtitle {
        margin-bottom: 30px;
      }

      .palette {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__color {
          size: ac(76px, 50px);
          border-radius: 50%;
          margin-bottom: 4px;
        }

        &__title {
          font-size: 14px;
          color: var(--grey);
        }
      }
    }

    &:nth-child(n + 5) {
      &.details-block--strategy {
        margin-left: 0;
      }
    }

    &--strategy {
      max-width: 182px;
      width: 100%;
      margin-left: auto;

      .subtitle {
        margin-bottom: 20px;
      }

      .strategy-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
          font-size: ac(18px, 16px);
          color: var(--grey);
          padding-bottom: 8px;
          border-bottom: 1px solid var(--grey);
        }
      }
    }

    @mixin min-media 1540 {
      &:first-child {
        max-width: 248px;
      }

      &:nth-child(2) {
        max-width: 184px;
        margin: 0;
      }

      &--strategy {
        /* max-width: unset; */
      }
    }
  }

  @mixin media 991 {
    .details-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }

    .details-block {
      &:nth-child(2),
      &:first-child,
      &--palette,
      &--strategy {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }

      .strategy-list {
        display: grid;
        gap: 10px;
        text-align: left;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @mixin media 551 {
    .details-list {
      text-align: center;
      grid-template-columns: 1fr;
    }

    .details-block {
      img {
        margin: 0 auto;
        max-width: 80px;
      }
    }
  }

  @mixin media 320 {
    .details-block {
      .strategy-list {
        grid-template-columns: 1fr;
      }
    }
  }
}

.project-images-list {
  margin-bottom: 20px;

  .image-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__item {
      max-width: 712px;
      width: vw(712);
      height: vw(780);
      max-height: 780px;

      /* &:nth-child(even) {
        margin-left: auto;
      } */

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.image-right {
    .image-list__item {
      margin-left: auto;
    }
  }
}

.project-posts {
  .posts-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    gap: ac(30px, 20px);

    &__item {
      flex-grow: 1;

      img {
        width: 100%;
      }
    }

    @mixin media 901 {
      grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
    }

    @mixin media 441 {
      grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    }
  }
}

.next-project {
  h2 {
    font-size: ac(100px, 40px);
    margin-bottom: ac(60px, 40px);

    /* &::after {
      content: '';
      position: relative;
      top: 2px;

      display: inline-block;

      width: 0.2em;
      height: 0.2em;

      border-radius: 999px;
      margin-left: 5px;

      background: var(--primary);
    } */
  }

  .work-card {
    min-height: ac(500px, 400px);
    clip-path: unset;
    aspect-ratio: unset !important;
  }
}

.about-project__client {
  display: flex;
  align-items: center;
  gap: 20px;

  margin-bottom: 27px;
}

.about-project__client-avatar {
  overflow: hidden;

  width: 71px;
  height: 71px;

  border-radius: 999px;
}

.about-project__client-avatar-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.about-project__client-name {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.22;
}

.about-project__client-position {
  font-size: 18px;
}

.project-images {
  img {
    margin-bottom: 30px;
  }
}

.project-image-slider {
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.two-img-row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  img {
    width: 100%;
  }

  @mixin media 651 {
    grid-template-columns: minmax(0, 1fr);
    gap: 20px;

    img {
      margin-bottom: 0;
    }
  }
}

.two-img-row-gap {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;

  @mixin media 551 {
    grid-template-columns: minmax(0, 1fr);
    gap: 0px;
  }
}
