.btn {
  --fz: 16px;
  --padding-y: 12px;
  --padding-x: 30px;

  color: var(--black);
  background: var(--primary);
  padding: var(--padding-y) var(--padding-x);
  border-radius: 999px;
  clip-path: inset(0 0 0 0 round 999px);
  font-weight: 600;
  font-size: var(--fz);
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;

  span {
    pointer-events: none;
    display: inline-block;
  }

  &.btn--lg {
    --fz: ac(22px, 16px);
    --padding-y: ac(24px, 20px);
    --padding-x: 60px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 100%;
    background: var(--white);
    border-radius: 50px;
    transition: top 0.5s cubic-bezier(0.4, 0, 0, 1),
      border-radius 0.5s cubic-bezier(0.4, 0, 0, 1);
  }

  [data-text] {
    position: relative;
    transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);

    &::before {
      content: attr(data-text);
      display: block;
      position: absolute;
      top: calc(100% + var(--padding-y) + 5px);
      left: 0;
      font-size: var(--fz);
      color: var(--black);
      font-weight: 600;
      transform: translateY(100%) skewY(15deg);
      transition: transform 0.5s ease;
    }
  }

  .btn__arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  &.btn--arrow {
    --fz: ac(34px, 20px);

    display: block;

    padding: ac(12px, 8px) ac(70px, 50px) ac(12px, 8px) ac(30px, 20px);

    line-height: 1.2;

    &.btn--black {
      color: var(--grey);

      .btn__arrow {
        background: var(--grey);
        color: var(--black);
      }
    }

    .btn__arrow {
      position: absolute;
      top: 50%;
      right: ac(30px, 20px);
      translate: 0 -50%;

      display: flex;
      align-items: center;
      justify-content: center;

      width: ac(33px, 25px);
      height: ac(33px, 25px);
      border-radius: 999px;

      background: var(--black);
      color: var(--primary);
      font-size: ac(12px, 10px);

      transition: all ease 500ms;
    }
  }

  &.btn-another-hover-text {
    [data-text] {
      &::before {
        left: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        transform: translate(-50%, 100%) skewY(15deg);
      }
    }

    &:hover {
      [data-text] {
        &::before {
          transform: translate(-50%, 0) skewY(0);
        }
      }
    }
  }

  &.btn--grey {
    background: var(--grey);
  }

  &.btn--black {
    background: var(--black);
    color: var(--white);

    .btn__arrow {
      background: var(--white);
      color: var(--black);
    }

    &:hover {
      .btn__arrow {
        background: var(--black);
        color: var(--white);
      }
    }
  }

  &.btn--white {
    background: var(--white);
  }

  &.btn--ghost {
    background: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);

    &:before {
      background: var(--primary);
    }
  }

  &.btn--grey,
  &.btn--white {
    &::before {
      background: var(--primary);
    }
  }

  &.btn--grey-hover {
    &::before {
      background: var(--grey);
    }
  }

  &:hover {
    transform: scaleX(1.02);
    transition: transform 0.6s cubic-bezier(0.34, 5.56, 0.64, 1);

    &::before {
      top: 0;
      border-radius: 0;
      transition-duration: 0.5s, 0.9s;
    }

    [data-text] {
      transform: translateY(calc(-100% - var(--padding-y) - 5px));

      &:before {
        transform: translateY(0) skewY(0);
      }
    }
  }
}

.showreel-btn {
  --el-width: 26px;
  --el-height: 26px;

  min-width: 127px;
  padding-left: calc(var(--el-width) + 8px);
  padding-right: 11px;

  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 400;

  position: relative;

  border-radius: 45px;
  background: var(--grey);
  color: var(--black);

  min-height: calc(var(--el-height) + 6px);
  transition: 0.3s ease;

  span {
    /* @mixin max-line-length 2; */
    /* max-width: 65px; */
    /* display: inline-block; */
    text-align: left;
    text-transform: capitalize;
  }

  &:before {
    content: '';
    position: absolute;
    width: var(--el-width);
    height: var(--el-height);
    background: var(--white);
    border-radius: 50%;
    left: 3px;
    top: 3px;
    bottom: 3px;
    transition: 0.3s ease;
  }

  &:hover {
    background: var(--primary);
    padding-left: 11px;
    padding-right: calc(var(--el-width) + 8px);

    &:before {
      background: var(--black);
      left: calc(100% - var(--el-height) - 3px);
    }
  }
}
.btn-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  border: 1px solid var(--primary);
  padding: ac(12px, 8px) ac(30px, 20px);
  border-radius: 999px;

  background: var(--primary);
  color: var(--black);

  font-family: var(--font-jeko);
  font-size: ac(34px, 20px);
  font-weight: 600;
  line-height: 1.2;

  transition: background ease 500ms;

  &:hover {
    background: var(--black);
    color: var(--primary);

    .btn-arrow__arrow {
      background: var(--primary);
      color: var(--black);
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ac(33px, 25px);
    height: ac(33px, 25px);
    border-radius: 999px;

    background: var(--black);
    color: var(--primary);
    font-size: ac(12px, 10px);

    transition: all ease 500ms;
  }

  &.black {
    background: var(--black);
    color: var(--white);

    border-color: transparent;

    .btn-arrow__arrow {
      background: var(--white);
      color: var(--black);
    }

    &:hover {
      background: var(--white);
      color: var(--black);

      .btn-arrow__arrow {
        background: var(--black);
        color: var(--white);
      }
    }
  }
}
