.brands {
  padding-top: ac(130px, 60px);
  padding-bottom: ac(150px, 62px);
  overflow: hidden;

  .brands-marquee-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    margin-top: 70px;

    font-weight: 500;
    font-size: ac(34px, 22px);
    margin-bottom: ac(96px, 60px);
    text-align: center;

    @mixin media 551 {
      margin-top: 40px;
      margin-bottom: 10px;
    }

    .round {
      display: inline-flex;
      align-items: center;

      border-color: var(--primary);

      /* position: relative;
      top: -0.1em; */

      /* .word {
        position: relative;
        top: 0.1em;
      } */
    }

    @mixin media 360 {
      font-size: 20px;
    }
  }

  .brands-logos {
    gap: ac(25px, 10px);
  }

  .brands-logos__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey);
      padding-bottom: ac(25px, 10px);
    }

    .marquee__content {
      animation-duration: 30s;
    }

    &:nth-child(even) {
      .marquee__content {
        animation-duration: 60s;
      }
    }

    height: 178px;
    max-height: 178px;

    & > div {
      height: 100%;
    }

    .logo {
      max-width: 178px;
      max-height: 70px;
    }
  }

  .marquee {
    --gap: 50px;

    @mixin media 1025 {
      /* transform: none !important; */
    }

    &.marquee--reverse {
      @mixin min-media 1024 {
        /* rotate: 0deg !important; */
      }
    }
  }

  &__count {
    font-weight: 700;
    font-size: ac(40px, 32px);
    flex-shrink: 0;
    white-space: nowrap;
    min-width: 142px;

    .plus {
      position: relative;
      bottom: 2px;
    }

    @mixin media 768 {
      min-width: auto;
    }
  }

  &-info {
    h2 {
      /* margin-bottom: -0.2em; */

      font-size: ac(46px, 32px);
    }
  }

  .brands-grid {
    margin-top: ac(150px, 40px);
  }
}

.brands-mob-slider {
  display: none;

  @mixin media 551 {
    display: block;

    margin-top: 20px;
  }
}

.brands-grid {
  overflow: hidden;
  width: 100vw;
  /* max-height: 1080px; */
  min-height: 600px;
  justify-content: center;

  --gap: 10px;

  display: flex;
  gap: var(--gap);

  @mixin media 551 {
    display: none;
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: var(--gap);
    margin-top: -100px;

    &:first-child {
      /* margin-left: vw(-197); */
    }

    &:nth-child(even) {
      margin-bottom: -150px;
      margin-top: 0;
    }
  }

  &__item {
    width: vw(605);
    height: vw(378);
    min-width: 375px;
    min-height: 234px;
    flex: 0 0 auto;

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: transform ease 250ms;
    }
  }

  &__link {
    width: 100%;
    height: 100%;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  @mixin min-media 1920 {
    &__item {
      width: vw(806, 1920);
      height: vw(504, 1920);
    }
  }

  @mixin media 551 {
    max-height: unset;

    flex-direction: column;

    &__col {
      width: 100vw;

      margin-top: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 0 !important;
    }

    &__item {
      width: 100vw;
      min-width: unset;
      min-height: unset;

      height: unset !important;
    }

    /* &__col {
            &:first-child,
            &:nth-child(odd) {
                display: none;
            }
        } */
  }
}

.brands-grid-wrap {
  position: relative;
}

.brands-grid__btns-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;

  @mixin media 551 {
    position: static;
    transform: none;
    max-width: 300px;
    margin: 20px auto 0;
  }
}

.brands-grid__btn {
  min-width: ac(500px, 250px);
}
