@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../../assets/fonts/HelveticaNeueLTPro-Md.eot');
  src: url('../../assets/fonts/HelveticaNeueLTPro-Md.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/HelveticaNeueLTPro-Md.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeueLTPro-Md.woff') format('woff'),
    url('../../assets/fonts/HelveticaNeueLTPro-Md.ttf') format('truetype'),
    url('../../assets/fonts/HelveticaNeueLTPro-Md.svg#HelveticaNeueLTPro-Md')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../../assets/fonts/HelveticaNeueLTPro-Bd.eot');
  src: url('../../assets/fonts/HelveticaNeueLTPro-Bd.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
    url('../../assets/fonts/HelveticaNeueLTPro-Bd.ttf') format('truetype'),
    url('../../assets/fonts/HelveticaNeueLTPro-Bd.svg#HelveticaNeueLTPro-Bd')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../../assets/fonts/HelveticaNeueLTPro-Roman.eot');
  src: url('../../assets/fonts/HelveticaNeueLTPro-Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeueLTPro-Roman.woff') format('woff'),
    url('../../assets/fonts/HelveticaNeueLTPro-Roman.ttf') format('truetype'),
    url('../../assets/fonts/HelveticaNeueLTPro-Roman.svg#HelveticaNeueLTPro-Roman')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../../assets/fonts/HelveticaNeueLTPro-Light.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeueLTPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Jeko */
/* @font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Medium.eot');
  src: url('../../assets/fonts/Jeko-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Medium.woff') format('woff'),
    url('../../assets/fonts/Jeko-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Bold.eot');
  src: url('../../assets/fonts/Jeko-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Bold.woff') format('woff'),
    url('../../assets/fonts/Jeko-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Light.eot');
  src: url('../../assets/fonts/Jeko-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Light.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Light.woff') format('woff'),
    url('../../assets/fonts/Jeko-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Black.eot');
  src: url('../../assets/fonts/Jeko-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Black.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Black.woff') format('woff'),
    url('../../assets/fonts/Jeko-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Black.eot');
  src: url('../../assets/fonts/Jeko-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Black.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Black.woff') format('woff'),
    url('../../assets/fonts/Jeko-Black.ttf') format('truetype'),
    url('../../assets/fonts/Jeko-Black.svg#Jeko') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Light.eot');
  src: url('../../assets/fonts/Jeko-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Light.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Light.woff') format('woff'),
    url('../../assets/fonts/Jeko-Light.ttf') format('truetype'),
    url('../../assets/fonts/Jeko-Light.svg#Jeko') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Medium.eot');
  src: url('../../assets/fonts/Jeko-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Medium.woff') format('woff'),
    url('../../assets/fonts/Jeko-Medium.ttf') format('truetype'),
    url('../../assets/fonts/Jeko-Medium.svg#Jeko') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jeko';
  src: url('../../assets/fonts/Jeko-Bold.eot');
  src: url('../../assets/fonts/Jeko-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/Jeko-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Jeko-Bold.woff') format('woff'),
    url('../../assets/fonts/Jeko-Bold.ttf') format('truetype'),
    url('../../assets/fonts/Jeko-Bold.svg#Jeko') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
