.hub-card {
  position: relative;
  min-height: ac(500px, 400px);
  border-radius: 39px;
  overflow: hidden;
  padding: ac(40px, 30px);
  cursor: pointer;

  display: flex;
  align-items: flex-end;

  &__img {
    z-index: -1;
    overflow: hidden;
    transition: 0.2s ease;

    &,
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      width: 150%;
      height: 150%;
      object-fit: cover;
    }

    &:before {
      content: '';
      background: var(--black);
      opacity: 0.6;
      pointer-events: none;
      z-index: 5;
    }
  }

  h3 {
    font-size: ac(46px, 32px);
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    max-width: 135px;
  }

  &:hover {
    .hub-card__img {
      transform: scale(1.05);
    }
  }
}
