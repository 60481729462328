.sort {
    .choices__inner {
        background: transparent;
        border: none;
        padding: 0 !important;

        min-height: 0;
    }

    .choices__list {
        padding: 0;
    }

    .choices__item--selectable {
        font-size: 18px;
        font-weight: 700;
    }

    .choices {
        min-width: 140px;

        &.is-open {
            &:after {
                transform: rotate(-180deg);
                margin-top: -4px !important;
            }
        }
    }

    .choices:after {
        width: 12px;
        height: 8px;
        border: none;
        margin-top: 0 !important;
        transform: translateY(-50%);
        right: 0;
        transition: 0.3s ease;

        background: url('data:image/svg+xml,<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 5.5L10 1" stroke="white" stroke-width="2"/></svg>')
            no-repeat center / contain;
    }

    .choices__list--dropdown {
        background-color: var(--black) !important;
        border-color: var(--primary) !important;
        border-radius: 8px;

        .choices__item--selectable {
            padding: 12px !important;
            background-color: transparent !important;
            transition: 0.3s ease;

            &.is-highlighted:not(.is-selected) {
                background: var(--primary) !important;
                color: var(--black);
            }

            &.is-selected {
                background: var(--primary) !important;
                color: var(--black);
                opacity: 0.5;
            }
        }
    }
}
