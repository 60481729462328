.testimonials {
  padding: ac(110px, 60px) 0;
  background: var(--primary);
  overflow: hidden;

  .cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    margin-bottom: ac(60px, 30px);

    @mixin media 651 {
      flex-direction: column;
      align-items: start;
    }
  }

  h2 {
    color: var(--black);
    max-width: ac(590px, 400px);
  }

  .marquee__wrap {
    gap: 10px;
    padding-bottom: 2px;

    &:hover {
      .marquee__content {
        animation-play-state: paused;
      }
    }
  }

  .marquee {
    &.enable-animation {
      .marquee__content {
        animation-duration: 35s;
      }
    }
  }

  &-card {
    padding: ac(30px, 24px);
    border: 1.5px solid var(--black);
    border-radius: 39px;
    color: var(--black);
    min-width: 335px;
    height: 100%;
    background: var(--primary);

    &.grey {
      background: var(--grey);
    }

    &.sm {
      max-width: vw(408);
      width: 100%;
    }

    &.lg {
      max-width: vw(514);
      width: 100%;
    }

    &__avatar {
      width: ac(71px, 60px);
      height: ac(71px, 60px);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    h3 {
      font-size: ac(22px, 18px);
    }

    h4 {
      font-size: ac(18px, 16px);
      font-weight: 500;
    }

    &__content {
      margin-top: auto;
    }

    .content-element {
      max-height: 13.5em;

      @mixin media 1025 {
        max-height: 11.5em;
      }

      h2 {
        font-weight: 400;
      }
    }
  }
}

.testimonials-hero {
  padding-top: ac(246px, 135px);
  padding-bottom: ac(70px, 30px);

  .bg-title {
    h1 {
      line-height: 75%;

      span:last-child {
        &::after {
          content: '';
          position: relative;
          top: 2px;
          left: -0.15em;

          display: inline-block;

          width: 0.15em;
          height: 0.15em;

          border-radius: 999px;

          background: var(--primary);
        }
      }
    }
  }

  .testimonials-grid {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: -60px auto 0;
    gap: 30px;
    max-width: 1270px;

    &[data-view] {
      opacity: 0;
      transform: translateY(100px);

      transition: all ease 750ms 1000ms;

      &.animated {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &__col {
      height: fit-content;
      flex-shrink: 0;
      width: calc(33% - 30px);
      max-width: 394px;

      &:first-child {
        margin-top: -30%;
      }

      &:last-child {
        margin-top: -50%;
      }

      @mixin media 1025 {
        width: 100%;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-top: 0;
        }
      }

      /* &:nth-child(2) {
                margin-top: -44vh;
            }*/
    }

    @mixin media 769 {
      padding-top: 80px;
    }
  }

  .testimonials-card {
    padding-bottom: 50px;
    min-width: auto;
    border: none;

    &:not(:last-child) {
      margin-bottom: ac(60px, 40px);
    }

    .content-element {
      p {
        font-size: ac(18px, 16px);
      }
    }
  }

  @mixin media 1025 {
    .testimonials-grid {
      flex-direction: column;

      &__col {
        display: grid;
        max-width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        transform: none !important;
      }
    }

    .testimonials-card {
      margin-bottom: 0 !important;

      &:last-child:nth-child(odd) {
        grid-column: 1 / -1;
      }
    }
  }

  @mixin media 769 {
    .testimonials-grid {
      &__col {
        grid-template-columns: 1fr;
      }
    }
  }
}

.testimonials-card {
  * {
    font-weight: 600;
  }
}

.testimonials-card__logo {
  flex-grow: 1;

  width: 100%;
  max-width: 70px;

  margin-left: auto;
}

.testimonials__arrow-btn {
  width: 100%;
  max-width: ac(500px, 350px);
}
