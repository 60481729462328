.work-card {
  position: relative;

  /* border-radius: 40px; */
  clip-path: inset(0 0 0 0 round 40px);
  overflow: hidden;
  padding: 40px;

  display: flex;
  align-items: flex-end;

  user-select: none;
  cursor: pointer;

  aspect-ratio: 4 / 5;

  @mixin media 769 {
    aspect-ratio: unset;
    min-height: 400px;
  }

  &__img {
    z-index: -1;
    transition: 0.2s ease-in-out;
    overflow: hidden;

    &,
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: '';
      background: linear-gradient(
        180deg,
        rgba(34, 34, 34, 0) 41.67%,
        rgba(34, 34, 34, 0.7) 100%
      );
      /* opacity: 0.6; */
      pointer-events: none;
      z-index: 5;
    }
  }

  h3 {
    margin-bottom: 10px;

    font-size: ac(46px, 32px);
    font-weight: 500;
    line-height: 1;
  }

  p {
    /* max-width: 135px; */
  }

  &:hover {
    .work-card__img {
      transform: scale(1.05);
    }
  }
}

.group-card__wrap {
  position: relative;
  z-index: 1;

  &:nth-child(3n + 1) {
    .group-card__popup {
      left: 0;
    }
  }
  &:nth-child(3n + 2) {
    .group-card__popup {
      left: 50%;
      transform: translate(-50%);
    }
  }
  &:nth-child(3n + 3) {
    .group-card__popup {
      right: 0;
    }
  }

  @mixin media 901 {
    &:nth-child(3n + 1) {
      .group-card__popup {
        left: unset;
      }
    }
    &:nth-child(3n + 2) {
      .group-card__popup {
        left: unset;
        transform: unset;
      }
    }
    &:nth-child(3n + 3) {
      .group-card__popup {
        right: unset;
      }
    }

    &:nth-child(odd) {
      .group-card__popup {
        left: 0;
      }
    }
    &:nth-child(even) {
      .group-card__popup {
        right: 0;
      }
    }
  }
}

.group-card__popup {
  position: absolute;
  top: calc(100% + 10px);
  overflow: hidden;

  max-width: 1276px;
  width: vw(1276);

  padding: ac(20px, 10px);

  border-radius: ac(40px, 20px);

  background: rgba(255, 85, 0, 0.8);

  visibility: hidden;
  opacity: 0;
  transform-origin: center top;
  scale: 1 0.5;

  transition: none;

  &.is-open {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    scale: 1;

    transition: all ease 500ms;

    .group-card__project-list {
      opacity: 1;
      transform: scaleY(1);

      .work-card {
        opacity: 1;

        transition: all ease 500ms;
      }
    }
  }

  .work-card {
    z-index: 1;
  }
}

.group-card__popup-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-card__popup-title {
  margin-bottom: 10px;

  font-size: ac(46px, 32px);
  font-weight: 500;
}

.group-card__project-list {
  /* display: grid; */
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  /* gap: 20px; */

  .work-card {
    opacity: 0;

    transition: none;
  }
}
