input,
textarea {
  background: transparent;
  color: var(--white);
  font-size: ac(36px, 18px);
  font-weight: 500;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey);
  border-radius: 0;

  @mixin transparent-bg-input var(--white);

  &::placeholder {
    color: var(--white);
    opacity: 0.7;
  }

  &:focus {
    & ~ .focus-line {
      width: 100%;
      transition: 0.4s;
      left: 0;
    }
  }

  &.black {
    border-color: var(--black);
    color: var(--black);

    @mixin transparent-bg-input var(--black);

    &::placeholder {
      opacity: 1;
      color: var(--black);
    }
  }

  &.sm {
    font-size: 30px;
  }
}

textarea {
  min-height: 90px;
  height: 90px;

  & ~ .focus-line {
    bottom: 6px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--grey);
    border-radius: 10px;
  }
}

.input-item {
  position: relative;

  input,
  textarea {
    transition: border-color ease 250ms;

    &.error {
      border-color: var(--error);
    }
  }

  .error-text {
    position: absolute;
    top: 100%;
    left: 0;

    width: 100%;

    font-size: ac(16px, 14px);

    color: var(--error);

    @mixin media 551 {
      position: static;
    }
  }
}

.focus-line {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--primary);
  transition: 0.4s;
  pointer-events: none;
}

.checkbox-item {
  display: flex;
  gap: 15px;

  cursor: pointer;

  font-size: 12px;

  @mixin media 601 {
    gap: 10px;
    font-size: 14px;
  }

  .custom-checkbox {
    position: relative;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 16px;
    height: 16px;

    border: 1px solid var(--black);

    @mixin media 601 {
      margin-top: 0.2em;
    }

    &::before {
      content: '';
      display: block;
      position: relative;
      top: -1px;
      /* position: absolute;
      top: 50%;
      left: 50%; */

      transform: rotate(45deg);
      height: 10px;
      width: 6px;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;

      transition: setTransition(border);
    }
  }

  .label {
    color: var(--black);
  }

  input:checked + .custom-checkbox {
    &::before {
      border-color: var(--black);
    }
  }

  input.error + .custom-checkbox {
    border-color: var(--error);
  }
}
