/* Marquee styles */
.marquee {
  --gap: 10px;
  position: relative;
  display: flex;
  user-select: none;
  gap: var(--gap);
  padding-bottom: 4px;

  .marquee__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    flex: 0 0 auto;

    /* > * {
            margin-right: var(--gap);
        }*/
  }

  /* Enable animation */
  /* &.enable-animation { */
  &.animated {
    .marquee__content {
      animation: scroll 15s linear infinite;
    }

    /* Enable position absolute animation on the duplicate content (last-child) */
    &.marquee--pos-absolute .marquee__content:last-child {
      animation-name: scroll-abs;
    }
  }

  /* Reverse animation */
  &.marquee--reverse {
    /* rotate: 180deg !important; */
    transform: rotate(180deg) !important;

    .marquee__content > * {
      transform: rotate(-180deg);
      /* rotate: -180deg !important; */

      &.logo {
        &:hover {
          transform: rotate(-180deg) scale(1.06);
        }
      }
    }
  }

  /* Pause on hover */
  &.marquee--hover-pause:hover .marquee__content {
    animation-play-state: paused;
  }

  /* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
  &.marquee--fit-content {
    max-width: fit-content;
  }

  /* A fit-content sizing fix: Absolute position the duplicate container. This will set the size of the parent wrapper to a single child container. Shout out to Olavi's article that had this solution 👏 @link: https://olavihaapala.fi/2021/02/23/modern-marquee.html  */
  &.marquee--pos-absolute .marquee__content:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  /* Pause animation when reduced-motion is set */
  @media (prefers-reduced-motion: reduce) {
    .marquee__content {
      animation-play-state: paused !important;
    }
  }
}

.marquee-string {
  background: var(--primary);
  padding: 12px 0;

  --gap: 30px;

  p {
    font-weight: 500;
    font-size: ac(34px, 22px);
  }

  .round-fill {
    background: var(--black);
    color: var(--white);
  }

  &--grey {
    background: var(--black);

    .round-fill {
      background: var(--primary);
    }
  }

  &--transp {
    background: transparent;

    .round-fill {
      background: var(--primary);
    }
  }

  &--black {
    background: var(--grey);
    color: var(--black);

    .round-fill {
      background: var(--primary);
      color: var(--black);
    }
  }
}

.marquee-vertical {
  --duration: 10s;
  --gap: 10px;
  --mask-direction: to bottom;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));

  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 50%,
    hsl(0 0% 0% / 1) 50%,
    hsl(0 0% 0% / 0)
  );

  &.animated {
    .marquee__content {
      animation: scroll-y var(--duration) linear infinite;
    }
  }

  .marquee__content {
    --duration: 10s;
    --gap: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    transition: all linear 250ms;
    gap: var(--gap);
    min-width: 100%;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0) translateZ(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap))) translateZ(0);
  }
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% - var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}
